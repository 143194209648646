.modal-pedidos {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  background: #00000036;
  z-index: 1100;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}



.pedido-form-container {
  background-color: white;
  position: fixed;
  top: 40px;
  width: 800px;
  max-width: 98%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  color: dimgray;
}

.header-form-pedido-container {
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-inline: 10px;
}

.footer-form-pedido-container {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content:space-between;
}

.footer-form-pedido-container-rigth {
  display: flex;
}


.close-button-form-pedidos {
  background: red;
  border: none;
  font-size: 12px;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  min-width: 30px;
  height: 30px;
  border-radius: 8px;
  color: white;

}

.close-button-form-pedidos:hover {
  background: rgb(151, 7, 7);
}



.pedido-form {
  padding-inline: 10px;

  position: relative;
  top: 0px;
  max-height: calc(100vh - 224px);

  overflow-y: auto;
}

.pedido-form h3 {
  margin-bottom: 15px;
}

.pedido-form label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

.pedido-form input,
.pedido-form textarea,
.pedido-form select {
  width: 100%;
  max-width: 98%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pedido-form textarea {
  height: 100px;
}




.styled-table {
  width: 100%;
  max-width: 98%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  min-width: 400px;
  border-radius: 0px;
  overflow: hidden;
}

.styled-table thead tr {
  background-color: #269bfa;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  color: rgb(82, 82, 82);
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #218ff7;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #218ff7;
}

.select-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.valor-total-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.valor-total-input {
  font-size: 1.5em;
  font-weight: bold;
  text-align: right;
  padding: 10px;
  border: none;
  background: transparent;
}

.input {
  border: 1px solid #ccc;
  /* Adiciona uma borda padrão */
}

.input:focus {
  outline: none;
  /* Remove a borda de foco padrão */
  border: 1px solid #007bff;
  /* Define uma borda de foco customizada */
}


.error {
  color: red;
  font-size: 12px;
}


/* Campo preenchido - fonte azul */
.input-preenchido {
  color: #007bff;
  /* Cor azul para indicar que está preenchido */
  border: 1px solid #ccc;
  /* Borda normal */
}

/* Campo não preenchido - borda vermelha */
.input-nao-preenchido {
  color: #000;
  /* Cor padrão para campos não preenchidos */
  border: 1px solid red;
  /* Borda vermelha */
}

.input-nao-preenchido:focus {
  border: 1px solid red;
  /* Mantém a borda vermelha ao focar em campos vazios */
}

.input-preenchido:focus {
  border: 1px solid #007bff;
  /* Borda azul ao focar em campos preenchidos */
}


.versions-container-pedidos {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.versions-container-pedidos-list {
  display: flex;
  align-items: center;


}

.versions-pedidos-list-item {
  padding-inline: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: slategray;
  color: white;
  margin-right: 8px;
  font-size: 14px;
  height: 35px;

}

.btn-add-versions-pedidos {
  padding-inline: 5px;

  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: slategray;
  color: white;
  background-color: #007bff;
  font-size: 14px;
  height: 35px;
  border: none;
}

.btn-activate-version {
  padding-inline: 5px;

  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  height: 35px;
  margin-right: 8px;
  background-color: rgb(255, 209, 5);
  margin-left: 8px;
  border: none;
}



.btn-atualizar-pedido-form {
  bottom: 25px;
  right: 5px;

  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  margin-right: 8px;
  background-color: #fa265b;
  margin-left: 8px;
  border: none;
  font-weight: 700;
  padding-inline: 5px;
}

.btn-atualizar-pedido-form:hover {
  background-color: rgb(255, 22, 5);
}

.item-list-version-pedido-column {

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.item-list-version-pedido-label {
  color: yellow;
  margin-left: 5px;
  font-size: 20px;
  margin-bottom: 3px;
}

.btn-activate-version-ativa {
  width: 80px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  margin-right: 8px;
  background-color: #269bfa;
  border: none;
  font-weight: 700;
}



.btn-save-only {
  right: 5px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  margin-right: 8px;
  background-color: #269bfa;
  margin-left: 8px;
  border: none;
  font-weight: 700;
  padding-inline: 5px;
}

.btn-save-only:hover {
  background-color: rgb(255, 22, 5);
}


.outros-container-pedidos {
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: center;
}


/* Botão para salvar temporariamente */
.btn-temporary-save {
  padding-inline: 5px;
  min-height: 30px;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  background-color: #007bff;

}

.btn-temporary-save:hover {
  background-color: #0056b3;
}

/* Botão para carregar dados temporários */



.temporary-form-container {
  width: 100px;
  display: flex;
  flex-direction: column;
  border: solid grey 1px;
  border-radius: 5px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: fixed;
  left: 10px;
  bottom: 50px;
  background-color: white
;
}


.btn-temporary-load {
padding-inline: 5px;
  min-height: 30px;
  border-radius: 5px;
  background-color: #28a745;
  color: white;
  font-size: 12px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.btn-temporary-load:hover {
  background-color: #218838;
}

/* Botão para excluir dados temporários */
.btn-temporary-delete {
  padding-inline: 5px;
  min-height: 30px;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  background-color: #dc3545;

}

.btn-temporary-delete:hover {
  background-color: #c82333;
}


.temporary-btns {
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.footer-form-pedido-container-row {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}

.footer-form-pedido-label-temporary {
  font-size: 13px;
}












.btn-export-pdf {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.btn-export-pdf:hover {
  background-color: #45a049;
}