.home-container {
    width: calc(100vw);
    margin-left: 0px;
    height: calc(100vh - 70px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
}

.tools-container {
    width: 100vw;
    max-height: 35px;
    min-height: 35px;
    display: flex;
    align-items: center;
}


/* -------- SEARCH CONTAINER -------- */

.search-card-container {
    max-width: calc(100vw - 10px);
    min-height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 5px;
}

.search-card-input {
    width: 270px;
    height: 30px;
    border: none;
    outline: none;
    padding-inline: 8px;
    font-size: 13px;
    background-color: #e0e0e0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.search-card-input:focus {
    background-color: #e0e0e0;
}

.search-card-input-local {
    width: 120px;
    height: 30px;
    border: none;
    outline: none;
    padding-inline: 8px;
    font-size: 12px;
    background-color: #e0e0e0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.search-card-input-local:focus {
    background-color: #e0e0e0;
}

.search-icon {
    background-color: rgb(126, 126, 126);
    width: 30px;
    height: 30px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.search-icon:hover {
    background-color: rgb(255, 20, 79)
}


.search-icon-open {

    background-color: rgb(199, 199, 199);
    width: 30px;
    height: 30px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 5px;
    margin-left: 6px;
    z-index: 101;
}

.search-icon-open:hover {
    background-color: deepskyblue;
}



.search-icon-close {
    position: relative;
    left: 280px;
    background-color: rgb(199, 199, 199);
    width: 30px;
    height: 30px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 5px;
    margin-left: 6px;
    z-index: 101;
}

.search-icon-close:hover {
    background-color: deepskyblue;
}


.btn-clear-search {
    min-width: 30px;
    height: 30px;
    background-color: rgb(255, 20, 79);
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 3px;
    font-size: 15px;
}

.btn-clear-search:hover {
    background-color: rgb(100, 100, 100);
}

/* -------- ORDER BY -------- */

.label-order-by-card {
    font-size: 13px;
    min-width: 80px;
    margin-left: 5px;
}

.btn-order-by-card {
    width: 80px;
    height: 22px;
    background-color: dodgerblue;
    color: white;
    border-radius: 5px;
    border: none;
    font-size: 13px;
    width: 100px;
    margin-right: 10px;
}

.search-result-container {
    width: 310px;
    max-height: calc(100vh - 113px);
    position: fixed;
    top: 70px;
    left: 325px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
    background-color: #363636d3;
    overflow-y: auto;
    padding-bottom: 5px;

}

.search-result-container::-webkit-scrollbar {
    width: 7px;
}

.search-result-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
}

.item-search-result-card {
    width: calc(100% - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-top: 5px;
}

.btn-import-search {
    height: 25px;
    background-color: rgb(184, 184, 184);
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 3px;
    padding-inline: 5px;
    margin-left: 5px;
}

.loading-container-search {
    width: calc(100% - 10px);
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;
    margin-top: 5px;
}








.input-search-afilhado-filter-afilhados {
    width: calc(100% - 10px);
    height: 30px;
    border: none;
    outline: none;
    padding-inline: 8px;
    font-size: 13px;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-left: 3px;

}

.input-search-afilhado-filter-afilhados:focus {
    background-color: #e0e0e0;
}




.select-filter {
    position: fixed;
    top: 70px;
    left: 6px;
    width: 460px;
    max-height: calc(100vh - 100px);
    color: #ffffff;
    font-size: 13px;
    border: none;
    background-color: #0000007c;

    z-index: 100;
    border-radius: 8px;
    padding-inline: 8px;
    overflow: hidden;
}

.list-afilhados-container {
    display: flex;
    max-height: calc(100vh - 180px);

    flex-direction: column;
    overflow-y: auto;

}

.list-afilhados-container::-webkit-scrollbar {
    width: 7px;
}

.list-afilhados-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
}

.select-filter-option {
    height: 42px;
    min-height: 42px;
    margin-bottom: 3px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #acacac;
}

.afilhados-icon-open {
    background-color: rgb(199, 199, 199);

    width: 30px;
    height: 30px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 5px;
    margin-left: 5px;
    z-index: 101;
}

.afilhados-icon-open:hover {
    background-color: deepskyblue;
}



.excel-icon-open {
    background-color: rgb(199, 199, 199);

    width: 30px;
    height: 30px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 5px;
    margin-left: 5px;
    z-index: 101;
}

.excel-icon-open:hover {
    background-color: deepskyblue;
}



.filter-icon-close-filter {
    position: absolute;
    left: 280px;
    background-color: rgb(199, 199, 199);

    width: 30px;
    height: 30px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 5px;
    margin-left: 5px;
    z-index: 101;
}

.afilhados-icon-close {
    position: absolute;
    left: 430px;
    background-color: rgb(199, 199, 199);

    width: 30px;
    height: 30px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 5px;
    margin-left: 5px;
    z-index: 101;
}

.afilhados-icon-close:hover {
    background-color: deepskyblue;
}










.date-icon-close {
    position: absolute;
    left: 280px;
    background-color: rgb(199, 199, 199);

    width: 30px;
    height: 30px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 5px;
    margin-left: 5px;
    z-index: 101;
}

.date-icon-close:hover {
    background-color: deepskyblue;
}







.logo-afilhado-lista {
    width: 38px;
    height: 38px;
    margin-right: 10px;
    background-color: white;
    border-radius: 5px;
    padding: 1px;
    object-fit: contain;
    margin-left: 2px;

}

.label-afilhados-lista {
    width: 100%;
}


.title-label-afilhados {
    width: 100%;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    font-weight: 800;
}

.modal-date-filter-container {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: rgba(12, 12, 12, 0.336);
    backdrop-filter: blur(0px);
}

.btn-close-modal-date {
    position: fixed;
    top: 10px;
    left: 10px;
    background-color: dodgerblue;
    color: white;
    border: none;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.733);
    /* Adiciona uma sombra */
    font-weight: 900;
    border: solid 2px white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    z-index: 101;
}

.btn-close-modal-date:hover {
    background-color: rgb(59, 59, 59);
    z-index: 100;
}

.date-filter-container {
    position: fixed;
    top: 70px;
    left: 6px;
    width: 310px;
    height: 180px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: rgb(199, 199, 199);
    flex-direction: column;
    background-color: #0000007c;
}

.date-filter-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-inline: 10px;
}

.date-filter-row {
    display: flex;
    margin-bottom: 5px;
}

.date-filter-label {
    font-size: 13px;
    color: white;
    font-weight: 700;
    margin-bottom: 5px;
}

.date-filter-date {
    min-height: 30px;
    border-radius: 5px;
    border: none;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-update-date-filter {
    min-height: 30px;
    width: calc(100% - 20px);
    border-radius: 5px;
    border: none;
    background-color: dodgerblue;
    color: white;

}

.btn-update-date-filter:hover {
    background-color: rgb(109, 109, 109);

}










.filter-select-columns {
    display: flex;
    flex-direction: column;
}

.btns-filter-select-columns {
    padding-inline: 2px;
    border: none;
    height: 23px;
    border-radius: 3px;
    margin-bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: calc(284px);
    background-color: #949494;
    color: white;
    font-size: 11px;

}


.filter-filter-container {

    position: fixed;
    top: 70px;
    left: 6px;
    width: 310px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    flex-direction: column;

    background-color: #0000007c;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    padding-top: 8px;
    padding-bottom: 8px;
}

.btn-filter-tools {
    padding-inline: 2px;
    border: none;
    height: 23px;
    border-radius: 3px;
    margin-bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: calc(284px);
    background-color: #949494;
    color: white;
    font-size: 11px;
}

.btn-filter-tools:hover {
    background-color: dodgerblue;
}


.select-etiquetas-filter {
    padding-inline: 2px;
    border: none;
    height: 23px;
    border-radius: 3px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: calc(284px);
    background-color: #ff5656;
    color: white;
    font-size: 11px;
}

.order-by-order {
    display: flex;
    align-items: center;
}

.btn-filter-tools-order {
    margin-inline: 2px;

    padding-inline: 2px;
    border: none;
    height: 23px;
    border-radius: 3px;
    margin-bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: calc(140px);
    background-color: #868686;
    color: white;
    font-size: 11px;
}

.btn-filter-tools-order:hover {
    background-color: dodgerblue;
}








.select-search {
    background-color: #0000007c;
    position: fixed;
    top: 70px;
    left: 6px;

    max-height: calc(100vh - 100px);
    color: #ffffff;
    font-size: 13px;
    border: none;
    border-radius: 8px;
    padding: 5px;
    overflow: hidden;
}

.serach-imput-container {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.search-type-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 30px;
    flex-direction: column;
    padding: 8px;
}


.label-search-radio-filtro-colunas {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    margin-top: 10px;
}

.label-search-radio {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
}

.input-radio-container {
    margin-right: 5px;
    display: flex;
    align-items: center;
    margin-left: 5px;
}


.state-divider {
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    background-color: #ffffff;
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #868686;
}





.label-user-type {
    background: rgb(95, 95, 95);
    border-radius: 5px;
    padding: 5px;
    margin-left: 10px;
    font-size: 10px;
    backdrop-filter: blur(5px);
}


.buscar-localmente-container {
    display: flex;
}

.btn-close-busca-local {
    width: 30px;
    height: 30px;
    margin-left: 4px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: rgb(255, 20, 59);
}


.btn-close-busca-local {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    margin-left: 4px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: rgb(78, 78, 78);
}


.btn-close-busca-local:hover {
    background-color: rgb(255, 20, 59);
}


.tipo-de-busca-local-container {
    width: 300px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 75px;
    left: 215px;
    z-index: 200;
    font-size: 14px;
    line-height: 1.6;
    color: #333;
    font-family: Arial, sans-serif;
    padding: 10px;
}

.search-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #444;
}

.search-options-list {
    list-style: none;
    padding-left: 0;
}

.search-options-list li {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.search-icon-info {
    margin-right: 8px;
    color: deepskyblue;
    font-size: 18px;
}

.search-options-list li:hover {
    color: dodgerblue;
    cursor: pointer;
}

.google-calendar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #33333346;
}

.google-calendar iframe {
    border: none;
    width: 95vw;
    height: 90vh;
    border-radius: 8px;
}



.label-temperatura-card {
    margin-top: 10px;
}
.temperatura-container-filter {
    width: calc(100% - 0px);
    height: 40px;
    background-color: #ebebeb;
    margin-top: 1px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-inline: 8px;

}

.btn-temperatura-frio-filter {
    width: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: none;
    background-color: #E41612;
    border-radius: 5px;
    color: white;
    height: 24px;
    font-size: 12px;
}


.btn-temperatura-frio-filter:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 8px -1px, rgba(255, 10, 10, 0.685) 0px 1px 3px -1px;
}

.btn-temperatura-morno-filter {
    width: 100%;
    border: none;
    background-color: #F9B200;
    margin-inline: 3px;
    border-radius: 5px;
    color: white;
    height: 24px;
    font-size: 12px;
}

.btn-temperatura-morno-filter:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 8px -1px, rgba(255, 10, 10, 0.685) 0px 1px 3px -1px;
}

.btn-temperatura-quente-filter {
    width: 100%;
    height: 24px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    background-color: #06A700;
    border-radius: 5px;
    color: white;
    font-size: 12px;
}

.btn-temperatura-quente-filter:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 8px -1px, rgba(255, 10, 10, 0.685) 0px 1px 3px -1px;
}


.btn-status-perdido-filter {
    width: 100%;
    height: 24px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    background-color: #E41612;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    margin-right: 3px;
}

.btn-status-perdido-filter:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 8px -1px, rgba(255, 10, 10, 0.685) 0px 1px 3px -1px;
}

.btn-status-vendido-filter {
    width: 100%;
    height: 24px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    background-color: #06A700;
    border-radius: 5px;
    color: white;
    font-size: 12px;
}

.btn-status-vendido-filter:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 8px -1px, rgba(255, 10, 10, 0.685) 0px 1px 3px -1px;
}



.btn-niveis-busca-cards {

    background-color: #acacac;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 3px;
    margin-left: 5px;
}

.btn-get-cards-especial {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    border: none;
    background-color: #696969;
    color: rgb(255, 255, 255);
    margin-inline: 3px;
    font-size: 11px;
}

.btn-get-cards-especial:hover {

    background-color:#7d19ff;
}


.btn-portal {
    margin-left: 5px;
    width: 30px;
    height: 30px;
    border: none;
    background-color: #424242;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.btn-portal:hover {
    background-color: #00a2ff;

}

.logo-bazze-portal {
    height: 26px;
}


@media (max-width: 599px) {
    .btn-niveis-busca-cards {
      display: none !important;
    
    }

    .excel-icon-open {
        display: none !important;
    }
  }
  