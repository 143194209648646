.TerminalProducao-container {
    min-height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    display: flex;
    flex-direction: column;
  }
  
  .TerminalProducao-header {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
  }
  
  .TerminalProducao-header-logo {
    display: flex;
    align-items: center;
  }
  
  .TerminalProducao-header-label {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    letter-spacing: 1px;
  }
  
  .TerminalProducao-buttons-area {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 30px;
  }
  
  .TerminalProducao-button {
    width: 160px;
    height: 160px;
    font-size: 14px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .TerminalProducao-button:hover {
    background-color: #2980b9;
    transform: scale(1.03);
  }

  .TerminalProducao-icon {
    width: 100px;
    height: 100px;
  }
  