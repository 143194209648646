/* FaktoryPage.css */

.FaktoryPage-container {
    height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.FaktoryPage-header {
    background-color: #ffffff;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
}

.FaktoryPage-logo-container {
    display: flex;
    align-items: center;
}

.FaktoryPage-logo-label {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    letter-spacing: 1px;
}

.FaktoryPage-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 98vw;
    height: 80vh;

}

.FaktoryPage-title {
    width: 98vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
    font-size: 18px;
    color: #2c3e50;
    margin-bottom: 0px;
    font-weight: 600;
    flex-direction: column;
    margin-left: 5px;
}

.FaktoryPage-input-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
    max-width: 900px;
    width: 98%;
    margin-bottom: 20px;
    background-color: #00000044;
    padding: 10px;
    border-radius: 5px;
}

.FaktoryPage-input-group {
    width: 100px;
    display: flex;
    flex-direction: column;
    min-width: 100px;
    flex: 1;
}

.FaktoryPage-input-label {
    font-size: 16px;
    color: #34495e;
    margin-bottom: 8px;
    font-weight: 500;
}

.FaktoryPage-input {
    padding: 12px;
    font-size: 16px;
    border: 2px solid #bdc3c7;
    border-radius: 6px;
    background-color: #fff;
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.FaktoryPage-input:focus {
    border-color: #3498db;
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.3);
}

.FaktoryPage-buttons-container {
    display: flex;
    gap: 15px;
    align-items: flex-end;
}

.FaktoryPage-scan-button-scan {
    padding: 14px;
    background-color: #ff275d;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
}

.FaktoryPage-scan-button-scan:hover {
    background-color: #eb6d8c;
}

.FaktoryPage-scan-button {
    padding: 8px;
    background-color: #20a6ff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
}

.FaktoryPage-scan-button:hover {
    background-color: #526cff;
}

.FaktoryPage-update-button {
    width: 60px;
    height: 45px;
    font-size: 16px;
    background-color: #ff275d;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.FaktoryPage-update-button:hover {
    background-color: #dd1648;
    transform: translateY(-2px);
}

.FaktoryPage-update-button:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
    transform: none;
}

.FaktoryPage-icon-scan {
    font-size: 36px;
}

.FaktoryPage-icon {
    font-size: 26px;
}

.FaktoryPage-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.FaktoryPage-scanner-container {
    background-color: #fff;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 350px;
}

.FaktoryPage-video-wrapper {
    position: relative;
    width: 300px;
    height: 300px;
    background-color: #000; /* Fundo preto para depuração */
}

.FaktoryPage-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    border: 2px solid #3498db;
    background-color: #000; /* Fundo preto para depuração */
}

.FaktoryPage-scan-area {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 70%;
    border: 2px dashed #ff275d;
    pointer-events: none;
}

.FaktoryPage-scanner-footer {
    display: flex;
    gap: 15px;
}

.FaktoryPage-toggle-button {
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.FaktoryPage-toggle-button:hover {
    background-color: #2980b9;
}

.FaktoryPage-cancel-button {
    padding: 10px 25px;
    font-size: 16px;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.FaktoryPage-cancel-button:hover {
    background-color: #c0392b;
}


.btn-voltar-header-faktory {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .icon-voltar-header-faktory {
    font-size: 30px;
    color: #333;
    transition: color 0.2s;
  }
  
  .icon-voltar-header-faktory:hover {
    color: #3498db;
  }



  .FaktoryPage-update-button-entrar {
    width: 60px;
    height: 45px;
    font-size: 16px;
    background-color: #00a2ff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.FaktoryPage-update-button-entrar:hover {
    background-color: #0691e2;
    transform: translateY(-2px);
}

.FaktoryPage-update-button-entrar:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
    transform: none;
}